import GATSBY_COMPILED_MDX from "/opt/build/repo/src/case-studies/russell-marine.md";
import React, {memo} from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import {MDXProvider} from "@mdx-js/react";
import {graphql} from 'gatsby';
import {makeStyles} from '@material-ui/core/styles';
import AngledSection from '../components/layout/AngledSection';
import DemoCallout from '../components/demo/DemoCallout';
import FadeIn from '../components/transitions/FadeIn';
import FadeInUp from '../components/transitions/FadeInUp';
import Layout from '../components/layout';
import ProductCards from '../components/product-cards/ProductCards';
import SEO from '../components/seo';
import theme from '../constants/theme/theme';
const useStyles = makeStyles({
  image: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '300px'
  },
  contentBody: {
    minHeight: '1000px'
  }
});
const components = {
  h1: (() => {
    const H1 = props => React.createElement(Typography, Object.assign({}, props, {
      component: "h2",
      variant: "h2"
    }));
    return memo(H1);
  })(),
  h2: (() => {
    const H2 = props => React.createElement(Typography, Object.assign({}, props, {
      component: "h3",
      variant: "h3"
    }));
    return memo(H2);
  })(),
  h3: (() => {
    const H3 = props => React.createElement(Typography, Object.assign({}, props, {
      component: "h4",
      variant: "h4"
    }));
    return memo(H3);
  })(),
  h4: (() => {
    const H4 = props => React.createElement(Box, {
      pb: 2,
      pt: 2
    }, React.createElement(Typography, Object.assign({}, props, {
      component: "h5",
      variant: "h5"
    })));
    return memo(H4);
  })(),
  p: (() => {
    const p = props => React.createElement(Box, {
      pb: 2,
      pt: 2
    }, React.createElement(Typography, Object.assign({}, props, {
      component: "p",
      variant: "body1"
    })));
    return memo(p);
  })()
};
const CaseStudy = ({data, children}) => {
  const classes = useStyles();
  const study = data.mdx;
  const {frontmatter, body} = study;
  return React.createElement(Layout, null, React.createElement(SEO, {
    title: `Case Study: ${frontmatter.title}`
  }), React.createElement(Box, {
    pb: 6,
    pt: 16,
    bgcolor: "secondary.dark",
    color: "primary.main"
  }, React.createElement(FadeIn, null, React.createElement(Container, {
    maxWidth: "md"
  }, React.createElement(Typography, {
    variant: "h6",
    component: "span"
  }, "CASE STUDY"), React.createElement(Typography, {
    variant: "h3",
    component: "h1",
    gutterBottom: true
  }, frontmatter.title)))), React.createElement(AngledSection, {
    color: theme.palette.secondary.dark,
    bottomColor: "#fff"
  }, React.createElement(FadeIn, null, React.createElement(Box, {
    className: classes.image,
    style: {
      backgroundImage: `url("${frontmatter.imgUrl}")`
    }
  }))), React.createElement(Box, {
    pb: 8,
    pt: 4,
    className: classes.contentBody
  }, React.createElement(Typography, {
    variant: "body1",
    component: "div"
  }, React.createElement(FadeInUp, null, React.createElement(Container, {
    maxWidth: "md"
  }, React.createElement(MDXProvider, {
    components: components
  }, children))))), React.createElement(AngledSection, null, React.createElement(Box, {
    bgcolor: "secondary.dark",
    pb: 12,
    pt: 12
  }, React.createElement(Box, {
    pb: 6
  }, React.createElement(Typography, {
    align: "center",
    variant: "h3",
    component: "h2"
  }, "SpeedyDock Products")), React.createElement(Container, null, React.createElement(ProductCards)))), React.createElement(DemoCallout, {
    bgColor: "#FFF"
  }));
};
export const pageQuery = graphql`
    query($slug: String!) {
        mdx(frontmatter: { slug: { eq: $slug } }) {
            frontmatter {
                imgUrl
                slug
                title
                desc
            }
        }
    }
`;
CaseStudy
export default function GatsbyMDXWrapper(props) {
  return React.createElement(CaseStudy, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
